import React, {useEffect, useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import TvIcon from '@material-ui/icons/Tv';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {httpRequestWithoutAuth, not_validate_value} from "../App";
import {BaseLocationSreenURL} from "../urls";
import {ErrorBox} from "../components/ErrorBox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Button from "@material-ui/core/Button";
import ListSubheader from "@material-ui/core/ListSubheader";
import {Hide} from "../components/Hide";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="#">
                Rekords
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#3f51b5',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    startAdornment: {
        color: '#3f51b5',
        paddingTop: theme.spacing(1),
        paddingRight: theme.spacing(2),
        fontSize: "20px",
        fontWeight: 'bolder'
    },
    endAdornment: {
        backgroundColor: '#3f51b5',
        color: "white",
        '&:hover': {
            background: 'darkblue',
        },
    }
}));

export const Home = props => {
    const classes = useStyles();
    const [screen_code, setScreenCode] = useState("")
    const [error, setError] = useState({})
    const [location_history, setLocationHistory] = useState([
        // {name:"asfadsf", screen_code:"fdsafsadf"},
        // {name:"hhhh", screen_code:"dddddd"},
    ]);

    useEffect(() => {
        let data = localStorage.getItem("location-history");

        if (!not_validate_value(data)) {
            setLocationHistory(JSON.parse(data));
        }

    }, []);

    const handleClickOnOption = async (screen_code) => {
        await props.history.push(`/${screen_code}`)
    }

    const handleDeleteLocation = async (index) => {
        let data = [...location_history];
        await data.splice(index, 1);
        await setLocationHistory(data);
        localStorage.setItem("location-history", JSON.stringify(data));
    }

    const onformSubmit = async e => {
        e.preventDefault()
        let response = await httpRequestWithoutAuth(BaseLocationSreenURL, "GET", null, {screen_code})
        if (response.status) {
            await props.history.push(`/${screen_code}`)
        } else {
            await setError(response.error_feedback)
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <TvIcon/>
                </Avatar>

                <Typography component="h1" variant="h5">
                    Rekords Screens
                </Typography>

                <form onSubmit={onformSubmit} className={classes.form}>

                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Screen Code"
                        name="screen_code"
                        error={error.screen_code !== undefined}
                        helperText={error.screen_code}
                        autoComplete="off"
                        autoFocus
                        value={screen_code}
                        onChange={e => {
                            setError({});
                            setScreenCode(e.target.value);
                        }}
                        InputProps={{
                            startAdornment: (<label className={classes.startAdornment}>#</label>),
                            endAdornment: (
                                <Tooltip title="View">
                                    <IconButton type="submit" size="small"
                                                className={classes.endAdornment} onClick={props.action}>
                                        <ArrowForwardIcon/>
                                    </IconButton>
                                </Tooltip>
                            ),
                        }}
                    />

                    <ErrorBox errorMsg={error.non_field_errors}/>
                </form>
            </div>

            <Hide hide={location_history.length < 1}>
                <List subheader={<ListSubheader>History</ListSubheader>}>
                    {location_history.map((Location, Index) => (
                        <ListItem key={Index}>

                            <Button component={ListItem} onClick={() => handleClickOnOption(Location.screen_code)}>
                                <ListItemAvatar className="mr-2">
                                    <Avatar className={classes.avatar}>
                                        <TvIcon/>
                                    </Avatar>
                                </ListItemAvatar>

                                <ListItemText
                                    primary={Location.name}
                                    secondary={Location.screen_code}
                                />

                            </Button>

                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteLocation(Index)}>
                                    <DeleteForeverIcon color="secondary"/>
                                </IconButton>
                            </ListItemSecondaryAction>

                        </ListItem>
                    ))}
                </List>
            </Hide>

            <Box mt={8}>
                <Copyright/>
            </Box>

        </Container>
    );
}
