import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import BackGroundImage from "../assets/images/screenBackground11.jpg";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import FontSizeChanger from 'react-font-size-changer';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import MaterialTable from "material-table";
import {LeaderBoardFormModal} from "./components/LeaderBoardFormModal";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {httpRequestWithoutAuth, not_validate_value, timeTo12Hour} from "../App";
import {BaseLocationSreenURL} from "../urls";
import {Hide} from "../components/Hide";

export const LocationScreenDisplay = props => {
    const [open_leader_form, setOpenLeaderForm] = useState(false)
    const [leader_form_data, setLeaderFormData] = useState({})
    const leader_board_columns = [
        {title: "Name", field: 'name'},
        {title: "Score", field: 'score', defaultSort: 'desc'},
    ];
    const [leader_board_data, setLeaderBoardData] = useState([
        {index: 0, name: "Osama Nader", score: 55},
        {index: 1, name: "Avo Jonation", score: 90},
        {index: 2, name: "Mazen Radwan", score: 77},
    ])


    const [update_screen, setUpdateScreen] = useState(0);

    const [location, setLocation] = useState({});
    const [training_areas, setTrainingAreas] = useState([]);
    const [classes, setClasses] = useState([]);
    const [workouts, setWorkouts] = useState([]);

    const [current_session, setCurrentSession] = useState({name: "Classes"})
    const [current_training_area, setCurrentTrainingArea] = useState({name: "training areas"});

    const [is_loading, setIsLoading] = useState(true)

    const [helper_text, setHelperText] = useState({})
    const [currentTab, setCurrentTab] = useState(0)
    const [open_training_areas_menu, setOpenTrainingAreasMenu] = React.useState(false);
    const [open_classes_menu, setOpenClassesMenu] = React.useState(false);

    useEffect(()=>{
        const updateScreen = async ()=>{
            let x = 0;
            setInterval(function() {
                x++;
                setUpdateScreen(x  % 60);
            }, 15 * 1000);
        }

        updateScreen();
    },[])

    useEffect(() => {
        const fetchData = async () => {
            const screen_code = props.match.params.screen_code || null;

            let response = await httpRequestWithoutAuth(`${BaseLocationSreenURL}`, "GET", null, {screen_code});

            if (response.status) {
                const {name, logo} = response.data;
                const {training_areas} = response.data;
                await setLocation({name, logo});
                await setTrainingAreas(training_areas);

                if (!not_validate_value(current_training_area.id)){
                    let is_available = false
                    training_areas.map(async TrainingArea =>{
                        if (TrainingArea.id === current_training_area.id){
                            is_available = true;
                            if (TrainingArea.is_all_day_workouts){
                                await setWorkouts(TrainingArea.all_day_workouts);
                            }

                            let time_now = moment().format("hh::mm A");
                                await TrainingArea.classes.map(async Session =>{
                                    let start = timeTo12Hour(Session.start_time);
                                    let end = timeTo12Hour(Session.end_time);
                                    // alert(start)
                                    // alert(end)
                                    if(moment("1995-06-09 " + time_now).isBetween( "1995-06-09 " + start, "1995-06-09 " + end) ){
                                        await setClasses(TrainingArea.classes);
                                        await setCurrentSession(Session);
                                        await setWorkouts(Session.workout_section);
                                    }
                                })
                        }
                    })

                    if (!is_available){
                        await setCurrentTrainingArea({name:"Training area"})
                        await setCurrentSession({name:"Classes"})
                    }
                }

            } else {
                props.history.push("/");
            }

            await setIsLoading(false)
        }


        fetchData();
        // setTimeout(customInterval, 5000)
        // setInterval(()=>fetchData(), 15 * 1000)
    }, [update_screen])


    const handleClassChange = async (data) =>{
        await setCurrentSession(data);
        await setWorkouts(data.workout_section);
        await setCurrentTab(0);
        await setOpenClassesMenu(false)
    }

    const handleTrainingAreaChange = async (data) =>{
        await setCurrentTrainingArea(data);
        await setClasses(data.classes);
        await setCurrentSession({name:'Classes'});
        await setCurrentTab(0);
        await setOpenTrainingAreasMenu(false);
        if (data.is_all_day_workouts){
            await setWorkouts(data.all_day_workouts);
        }else{
            await setWorkouts([]);
        }
    }


    const handleClassesMenuClick = (event) => {
        setOpenClassesMenu(event.currentTarget);

    };

    const handleTrainingAreaMenuClick = (event) => {
        setOpenTrainingAreasMenu(event.currentTarget);
    };

    const handleClose = () => {
        setOpenTrainingAreasMenu(null);
        setOpenClassesMenu(null);
    };

    const handleLeaderFormChange = (name, newValue) => {
        setLeaderFormData({...leader_form_data, [name]: newValue})
    }

    const handleOpenLeaderForm = (data, index) => {
        setOpenLeaderForm(true)
        setLeaderFormData({...data, 'index': index})
    }

    const handleCloseLeaderForm = data => {
        setOpenLeaderForm(false)
        setLeaderFormData({})
    }

    const handleSaveLeaderForm = async () => {
        let data = [...leader_board_data]
        if (leader_form_data.id === undefined) {
            data.push({...leader_form_data, id: "1"})
            await setLeaderBoardData(data)
        } else {
            data[leader_form_data.index] = {...leader_form_data}
            await setLeaderBoardData(data)
        }
        await setOpenLeaderForm(false)
        await setLeaderFormData({})
    }

    const handleDeleteScore = async rowData => {
        let data = [...leader_board_data]
        data.splice(rowData.index, 1);
        await setLeaderBoardData(data)
    }

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };



    return (
        <div style={style.root}>

            <Hide hide={is_loading}>

                <Grid container style={style.container}>

                    <Grid item xs={12}>
                        <Toolbar className="font-weight-bold">

                            <img src={location.logo} alt="logo" style={style.logo}/>


                            <Tabs
                                value={currentTab}
                                onChange={handleTabsChange}
                                classes={{indicator: "bg-dark"}}
                                style={style.itemTab}
                                variant="fullWidth"
                            >
                                {workouts.map((Workout, index) => (
                                    <Tab key={index} label={Workout.header} id={index}/>
                                    ))}
                                <Tab label="Leader Board" id={(workouts || []).length}/>
                            </Tabs>

                            <div className="mr-auto"/>


                            <div className="mr-3 font-weight-bold">
                                <Button
                                    onClick={handleTrainingAreaMenuClick}
                                    className='text-dark'
                                    size="small"
                                    endIcon={<ArrowDropDownIcon/>}
                                >
                                    <Typography variant='subtitle1' className="font-weight-bold">
                                        {current_training_area.name}
                                    </Typography>
                                </Button>

                                <Menu
                                    anchorEl={open_training_areas_menu}
                                    keepMounted
                                    id="training_areas"
                                    open={Boolean(open_training_areas_menu)}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: style.classMenu
                                    }}
                                >
                                    {training_areas.map(TrainingArea=>(
                                        <MenuItem key={TrainingArea.name} style={style.classMenuItem} onClick={()=>handleTrainingAreaChange(TrainingArea)}>{TrainingArea.name}</MenuItem>
                                    ))}
                                    <Hide hide={training_areas.length !== 0}>
                                        <MenuItem style={style.classMenuItem} onClick={()=>handleClose()}>No Training Area</MenuItem>
                                    </Hide>
                                </Menu>
                            </div>


                            <div className="mr-3 font-weight-bold">
                                <Button
                                    onClick={handleClassesMenuClick}
                                    className='text-dark'
                                    size="small"
                                    endIcon={<ArrowDropDownIcon/>}
                                >
                                        <Typography variant='subtitle1' className="font-weight-bold">
                                            {current_session.name}
                                        </Typography>
                                </Button>

                                <Menu
                                    anchorEl={open_classes_menu}
                                    keepMounted
                                    id="classes"
                                    open={Boolean(open_classes_menu)}
                                    onClose={handleClose}
                                    PaperProps={{
                                        style: style.classMenu
                                    }}
                                >
                                    {classes.map(Session=>(
                                        <MenuItem key={Session.name} style={style.classMenuItem} onClick={()=>handleClassChange(Session)}>{Session.name}</MenuItem>
                                    ))}
                                    <Hide hide={classes.length !== 0}>
                                        <MenuItem style={style.classMenuItem} onClick={()=>handleClose()}>No Classes</MenuItem>
                                    </Hide>
                                </Menu>
                            </div>

                            <Typography variant="subtitle1" className="font-weight-bold">
                                {moment().format("dddd, DD MMM YYYY")}
                            </Typography>

                            <div className="ml-3">
                                <FontSizeChanger
                                    targets={['.content']}
                                    options={{
                                        stepSize: 5,
                                        range: 10
                                    }}
                                    customButtons={{
                                        up: <ZoomInIcon/>,
                                        down: <ZoomOutIcon/>,
                                        style: style.fontSizeIcon,
                                    }}
                                />
                            </div>

                        </Toolbar>
                    </Grid>

                    <Grid item xs={12} className="h-100 p-0 m-0 overflow-hidden">
                        <div style={style.item} className="content">
                            {workouts.map((Workout, index) => (
                                <Hide hide={index !== currentTab} key={Workout.header}>

                                    {/*<Typography variant="h3" style={style.itemHeader}>*/}
                                    {/*    {Workout.header}*/}
                                    {/*</Typography>*/}

                                    <div className="overflow-auto h-100">
                                        <Typography variant="subtitle1" style={style.itemContent}>
                                            {Workout.content}
                                        </Typography>
                                    </div>

                                </Hide>
                            ))}

                            <Hide hide={workouts.length !== currentTab}>

                                <Hide hide={!open_leader_form}>
                                    <LeaderBoardFormModal
                                        open={open_leader_form}
                                        handleModalClose={handleCloseLeaderForm}
                                        helper_text={helper_text}
                                        data={leader_form_data}
                                        onChangeHandle={handleLeaderFormChange}
                                        handleSaveForm={handleSaveLeaderForm}
                                    />
                                </Hide>

                                <MaterialTable
                                    columns={leader_board_columns}
                                    data={leader_board_data}

                                    editable={{
                                        onRowDelete: async rowData => {
                                            await handleDeleteScore(rowData)
                                        }
                                    }}

                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: "Edit Score",
                                            onClick: (event, rowData) => handleOpenLeaderForm(rowData, rowData.index)
                                        },
                                        {
                                            icon: () => <Button
                                                startIcon={<AddIcon/>} variant="outlined">Add
                                                Score</Button>,
                                            tooltip: "Add Score",
                                            isFreeAction: true,
                                            onClick: (event, rowData) => handleOpenLeaderForm(rowData.data, undefined)
                                        },
                                    ]}

                                    style={style.leaderBoardTable}


                                    options={{
                                        showTitle: false,
                                        searchFieldAlignment: 'left',
                                        paging: false,
                                        sorting: false,
                                        filtering: false,
                                        actionsColumnIndex: -1,
                                    }}

                                    localization={{
                                        body: {
                                            emptyDataSourceMessage: <span className="text-white">No records to display</span>,
                                            editRow: {
                                                deleteText: <span className="text-white">Are you sure you want to delete this row?</span>,
                                            },
                                        }
                                    }}
                                />

                            </Hide>
                        </div>

                    </Grid>

                </Grid>

            </Hide>

        </div>

    );
}


const style = {
    root: {
        backgroundImage: `url(${BackGroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // backgroundColor: '#f8f9fa',
        height: '100vh',
        overflow: "hidden",
        // fontFamily: "Open Sans', sans-serif"
    },
    container: {
        height: "100%",
        overflow: "hidden",
        paddingLeft: "2rem",
        paddingRight: "2rem",
    },
    logo:{
        height: "50px",
        marginRight: "1rem",
    },
    item: {
        // background: "rgba(0,0,0,0.5)",
        height: "100%",
        width: "100%",
        // border: "2px solid whitesmoke",
        overflow: "hidden",
        padding: "0"
    },
    itemTab: {
        textAlign: "center",
        fontWeight: "bold",
        // background: "rgba(0,0,0,0.2)",
        // marginBottom: "1rem",
        // marginInlineStart: "1rem",
        // paddingTop: '1.25rem'
    },
    itemContent: {
        fontSize: "5vw",
        padding: "0 0 3rem 0",
        margin: "0",
        textAlign: "center",
        whiteSpace: 'pre-line',
    },
    btn: {
        borderColor: 'black',
        color: 'black',
    },
    fontSizeIcon: {
        width: '35px',
        height: '35px',
        padding: '0',
        margin: '0',
        border: 'none',
    },
    classMenu:{
        marginTop:"40px",
        // background: "rgba(0,0,0,0.9)",
        minWidth: '200px',
        border: "1px solid #3f51b5",
    },
    classMenuItem:{
        background: "none",
        minWidth: '200px',
    },
    leaderBoardTable:{
        background: 'rgba(255,255,255,0.5)',
        shadow: "none",
    }
}

