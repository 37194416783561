import React from 'react';
import './App.css';
import {Home} from "./pages/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import {BaseBackEndURL} from "./urls";
import moment from "moment";
import {LocationScreenDisplay} from "./pages/LocationScreenDisplay";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {LocationScreenDisplayNew} from "./pages/LocationScreenDisplayNew";
import {LocationScreenDisplayNew2} from "./pages/LocationScreenDisplayNew2";


export async function httpRequestWithoutAuth(url, method, data, params) {
    url = BaseBackEndURL + url
  let feedback ;
  try {

    feedback = await axios(url,
        {
          method: method,
          data,
          params
        }
    ).then(res => {
      return {status: true, msg: res.statusText, data: res.data}
    })
        .catch(error => {
          return {status: false, msg: error.response.status, error_feedback: error.response.data}
        });

  }catch (e) {
    feedback =  {status: false, fatal_error:true, msg: "Something went wrong, try again", error_feedback:{}}
  }

  return feedback

}

export const not_validate_value = (value) =>{
    return ["", undefined, null].includes(value)
}


export function timeTo12Hour(time) {
    return moment("1995-06-09 " + time).format("hh:mm A")
}

export function timeTo24Hour(time) {
    return moment("1995-06-09 " + time).format("hh:mm")
}


function App() {
  return (
      <BrowserRouter>
          <Switch>
              <Route path='/:screen_code' component={LocationScreenDisplayNew2}/>
              <Route path="/" component={Home}/>
          </Switch>
      </BrowserRouter>

  );
}

export default App;
