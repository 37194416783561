import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

export const DisplayPersonData = props =>{
        return(
            <div style={styles.container}>
                <Typography variant="h5" className="ml-2 mr-2">
                    {props.index}
                </Typography>

                <Avatar src={props.photo} style={styles.avatar} />

                <Typography variant="h5">
                    {props.name}
                </Typography>

            </div>
        )
}

const styles= {
    container:{
        width: '100%',
        display:'inline-flex',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        border: "2px solid black",
        borderRadius: '10px',
        padding: '1rem',
        alignItems: 'center'
    },
    avatar:{
        width: '5rem',
        height: '5rem',
        marginRight: "1rem",
        marginLeft: "1rem"
    }
}
