import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FontSizeChanger from 'react-font-size-changer';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Button from "@material-ui/core/Button";
import moment from "moment";
import Toolbar from "@material-ui/core/Toolbar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {httpRequestWithoutAuth, not_validate_value, timeTo12Hour} from "../App";
import {BaseLocationSreenURL} from "../urls";
import {Hide} from "../components/Hide";
import {DisplayPersonData} from "../components/DisplayPersonData";
import queryString from 'query-string'

export const LocationScreenDisplayNew2 = props => {
    let query_string = queryString.parse(props.location.search) || {}
    let defualt_training_area = query_string.training_area
    let defualt_view = query_string.view

    const [open_leader_form, setOpenLeaderForm] = useState(false)
    const [leader_form_data, setLeaderFormData] = useState({})
    const leader_board_columns = [
        {title: "Name", field: 'name'},
        {title: "Score", field: 'score', defaultSort: 'desc'},
    ];
    const [leader_board_data, setLeaderBoardData] = useState([
        {index: 0, name: "Osama Nader", score: 55},
        {index: 1, name: "Avo Jonation", score: 90},
        {index: 2, name: "Mazen Radwan", score: 77},
    ])


    const [update_screen, setUpdateScreen] = useState(0);

    const [view, setView] = useState(defualt_view || 'WOD');
    const [location, setLocation] = useState({});
    const [training_areas, setTrainingAreas] = useState([]);
    const [classes, setClasses] = useState([]);
    const [attendance, setAttendance] = useState([]);
    const [workouts, setWorkouts] = useState([]);

    const [current_session, setCurrentSession] = useState({name: "Classes"})
    const [current_training_area, setCurrentTrainingArea] = useState({name: "training areas"});

    const [is_loading, setIsLoading] = useState(true)

    const [helper_text, setHelperText] = useState({})
    const [currentTab, setCurrentTab] = useState(0)
    const [open_view_menu, setOpenViewMenu] = React.useState(false);
    const [open_training_areas_menu, setOpenTrainingAreasMenu] = React.useState(false);
    const [open_classes_menu, setOpenClassesMenu] = React.useState(false);

    const updateLocationHistory = (location_data) => {
        let data = localStorage.getItem("location-history");

        if (!not_validate_value(data)) {
            data = JSON.parse(data);
        }else{
            data = []
        }

        let is_available = -1;

        data.map((Location, Index) => {
            if (Location.screen_code === location_data.screen_code) {
                is_available = Index
            }
        });

        if (is_available > -1){
            data.splice(is_available, 1);
        }

        data = [{name: location_data.name, screen_code: location_data.screen_code}, ...data];

        localStorage.setItem("location-history", JSON.stringify(data));
    }

    useEffect(() => {
        const updateScreen = async () => {
            let x = 0;
            setInterval(function () {
                x++;
                setUpdateScreen(x % 60);
            }, 15 * 1000);
        }

        updateScreen();
    }, [])

    const checkCurrentClass = async TrainingArea => {
        let format = 'hh:mm:ss';

        let time_now = moment();

        await TrainingArea.classes.map(async Session => {
            let start = moment(Session.start_time, format);
            let end = moment(Session.end_time, format);

            if (time_now.isBetween(start, end)) {
                await setClasses(TrainingArea.classes);
                await setCurrentSession(Session);
                await setAttendance(Session.attendance || []);
                await setWorkouts(Session.workout_section);
            }
        })
    }

    useEffect(() => {
        const fetchData = async () => {
            const screen_code = props.match.params.screen_code || null;

            let response = await httpRequestWithoutAuth(`${BaseLocationSreenURL}`, "GET", null, {screen_code});

            if (response.status) {
                await updateLocationHistory({name: response.data.name, screen_code: screen_code});
                const {name, logo} = response.data;
                const {training_areas} = response.data;
                await setLocation({name, logo});
                await setTrainingAreas(training_areas);

                if (!not_validate_value(current_training_area.id || defualt_training_area) ||
                    (not_validate_value(current_training_area.id || defualt_training_area) && training_areas.length > 0)) {
                    let is_available = false
                    training_areas.map(async (TrainingArea) => {
                        if (TrainingArea.id === (current_training_area.id || defualt_training_area)) {
                            is_available = true;
                            await handleTrainingAreaChange(TrainingArea);
                            await checkCurrentClass(TrainingArea);
                        }
                    })

                    if (!is_available) {
                        if (training_areas.length > 0) {
                            await handleTrainingAreaChange(training_areas[0]);
                            await checkCurrentClass(training_areas[0]);
                        } else {
                            await setCurrentTrainingArea({name: "Training area"});
                            await setCurrentSession({name: "Classes"});
                            await setAttendance([]);
                        }
                    }
                }

            } else {
                props.history.push("/");
            }

            await setIsLoading(false)
        }


        fetchData();
        // setTimeout(customInterval, 5000)
        // setInterval(()=>fetchData(), 15 * 1000)
    }, [update_screen])


    const handleClassChange = async (data) => {
        if (current_session.id !== data.id) {
            setCurrentSession({...data});
            setWorkouts([...data.workout_section]);
            setAttendance([...data.attendance]);
            setCurrentTab(0);
        }
        setOpenClassesMenu(false)
    }

    const handleTrainingAreaChange = async (data) => {
        let url_params = {training_area: data.id, view};

        props.history.push({
            pathname: props.location.pathname,
            search: "?" + new URLSearchParams(url_params).toString()
        });

        let temp_current_class = {name: 'Classes'};
        let temp_current_class_attendance = [];
        let temp_current_workout  = [];


        data.classes.map(Session=>{
            if (current_session.id === Session.id){
                temp_current_class = {...Session};
            }
        })

        if (data.is_all_day_workouts) {
            temp_current_workout = [...data.all_day_workouts];
        }else if(!not_validate_value(temp_current_class.id)){
            temp_current_workout = [...temp_current_class.workout_section];
        }else {
            temp_current_workout = [];
        }

        if (!not_validate_value(temp_current_class.id)){
            temp_current_class_attendance = [...temp_current_class.attendance]
        }

        await setCurrentTrainingArea(data);
        await setClasses([...data.classes]);
        await setCurrentSession(temp_current_class);
        await setAttendance(temp_current_class_attendance);
        await setWorkouts(temp_current_workout);

        if (currentTab >= temp_current_workout.length){
            await setCurrentTab(0);
        }


        setOpenTrainingAreasMenu(false);
    }

    const handleViewChange = (value) => {
        setView(value);
        let url_params = {};

        if (!not_validate_value(current_training_area.id)) {
            url_params['training_area'] = current_training_area.id;
        }
        url_params['view'] = value;


        props.history.push({
            pathname: props.location.pathname,
            search: "?" + new URLSearchParams(url_params).toString()
        });
        handleClose();
    }
    const handleViewMenuClick = (event) => {
        setOpenViewMenu(event.currentTarget);

    };

    const handleClassesMenuClick = (event) => {
        setOpenClassesMenu(event.currentTarget);

    };

    const handleTrainingAreaMenuClick = (event) => {
        setOpenTrainingAreasMenu(event.currentTarget);
    };

    const handleClose = () => {
        setOpenViewMenu(null);
        setOpenTrainingAreasMenu(null);
        setOpenClassesMenu(null);
    };

    const handleLeaderFormChange = (name, newValue) => {
        setLeaderFormData({...leader_form_data, [name]: newValue})
    }

    const handleOpenLeaderForm = (data, index) => {
        setOpenLeaderForm(true)
        setLeaderFormData({...data, 'index': index})
    }

    const handleCloseLeaderForm = data => {
        setOpenLeaderForm(false)
        setLeaderFormData({})
    }

    const handleSaveLeaderForm = async () => {
        let data = [...leader_board_data]
        if (leader_form_data.id === undefined) {
            data.push({...leader_form_data, id: "1"})
            await setLeaderBoardData(data)
        } else {
            data[leader_form_data.index] = {...leader_form_data}
            await setLeaderBoardData(data)
        }
        await setOpenLeaderForm(false)
        await setLeaderFormData({})
    }

    const handleDeleteScore = async rowData => {
        let data = [...leader_board_data]
        data.splice(rowData.index, 1);
        await setLeaderBoardData(data)
    }

    const handleTabsChange = (event, newValue) => {
        setCurrentTab(newValue)
    };


    return (
        <div style={style.root}>

            <Hide hide={is_loading}>

                <div style={style.container}>

                    <Toolbar className="font-weight-bold p-0">

                        <Typography variant="h6" className="font-weight-bold mr-3">
                            {moment().format("dddd, DD MMM YYYY")}
                        </Typography>

                        <Typography variant="h6" className="font-weight-bold mr-3">
                            /
                        </Typography>

                        <Typography variant="h6" className="font-weight-bold text-uppercase mr-3">
                            {location.name}
                        </Typography>

                        <Typography variant="h6" className="font-weight-bold m-2">
                            /
                        </Typography>

                        {/*<Hide hide={true}>*/}
                        {/*<div className="mr-3 font-weight-bold">*/}
                        {/*    <Button*/}
                        {/*        onClick={handleViewMenuClick}*/}
                        {/*        className='text-white'*/}
                        {/*        size="small"*/}
                        {/*        endIcon={<ArrowDropDownIcon/>}*/}
                        {/*    >*/}
                        {/*        <Typography variant="h6" className="font-weight-bold">*/}
                        {/*            {view}*/}
                        {/*        </Typography>*/}
                        {/*    </Button>*/}

                        {/*    <Menu*/}
                        {/*        anchorEl={open_view_menu}*/}
                        {/*        keepMounted*/}
                        {/*        id="training_areas"*/}
                        {/*        open={Boolean(open_view_menu)}*/}
                        {/*        onClose={handleClose}*/}
                        {/*        PaperProps={{*/}
                        {/*            style: style.classMenu*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <MenuItem style={style.classMenuItem} onClick={()=> handleViewChange("WOD")}>WOD</MenuItem>*/}
                        {/*        <MenuItem style={style.classMenuItem} onClick={()=> handleViewChange("Attendance")}>Attendance</MenuItem>*/}

                        {/*    </Menu>*/}
                        {/*</div>*/}
                        {/*</Hide>*/}

                        {/*<Typography variant="h6" className="font-weight-bold text-uppercase">*/}
                        {/*    {view}*/}
                        {/*</Typography>*/}

                        {/*<img src={location.logo} alt="logo" style={style.logo}/>*/}


                        {/*<Tabs*/}
                        {/*    value={currentTab}*/}
                        {/*    onChange={handleTabsChange}*/}
                        {/*    // classes={{indicator: "bg-dark"}}*/}
                        {/*    classes={{indicator: "bg-white"}}*/}
                        {/*    style={style.itemTab}*/}
                        {/*    variant="fullWidth"*/}
                        {/*>*/}
                        {/*    {workouts.map((Workout, index) => (*/}
                        {/*        <Tab key={index} label={Workout.header} id={index}/>*/}
                        {/*        ))}*/}
                        {/*    /!*<Tab label="Leader Board" id={(workouts || []).length}/>*!/*/}
                        {/*</Tabs>*/}

                        <Button
                            onClick={() => handleViewChange("WOD")}
                            className='text-white'
                            size="small"
                        >
                            <Typography variant='subtitle1'
                                        className={view === "WOD" ? "font-weight-bolder" : "font-weight-bold"}>
                                WOD
                            </Typography>
                        </Button>

                        <Typography variant='subtitle1' className="font-weight-bolder mr-2 ml-1">
                            |
                        </Typography>

                        <Button
                            onClick={() => handleViewChange("Attendance")}
                            className='text-white mr-2'
                            size="small"
                        >
                            <Typography variant='subtitle1'
                                        className={view === "Attendance" ? "font-weight-bolder" : "font-weight-bold"}>
                                Attendance
                            </Typography>
                        </Button>

                        <div className="mr-auto"/>

                        <div className="mr-3 font-weight-bold">
                            <Hide hide={training_areas.length < 2}>
                                <Button
                                    onClick={handleTrainingAreaMenuClick}
                                    className='text-white'
                                    size="small"
                                    endIcon={<ArrowDropDownIcon/>}
                                >
                                    <Typography variant='subtitle1' className="font-weight-bold">
                                        {current_training_area.name}
                                    </Typography>
                                </Button>
                            </Hide>

                            <Hide hide={training_areas.length > 1}>
                                <Typography variant='subtitle1' className="font-weight-bold">
                                    {current_training_area.name}
                                </Typography>
                            </Hide>

                            <Menu
                                anchorEl={open_training_areas_menu}
                                keepMounted
                                id="training_areas"
                                open={Boolean(open_training_areas_menu)}
                                onClose={handleClose}
                                PaperProps={{
                                    style: style.classMenu
                                }}
                            >
                                {training_areas.map(TrainingArea => (
                                    <MenuItem key={TrainingArea.name} style={style.classMenuItem}
                                              onClick={() => handleTrainingAreaChange(TrainingArea)}>{TrainingArea.name}</MenuItem>
                                ))}
                                <Hide hide={training_areas.length !== 0}>
                                    <MenuItem style={style.classMenuItem} onClick={() => handleClose()}>No Training
                                        Area</MenuItem>
                                </Hide>
                            </Menu>
                        </div>


                        <div className="mr-3 font-weight-bold">
                            <Button
                                onClick={handleClassesMenuClick}
                                className='text-white'
                                size="small"
                                endIcon={<ArrowDropDownIcon/>}
                            >
                                <Typography variant='subtitle1' className="font-weight-bold">
                                    <Hide hide={!not_validate_value(current_session.start_time)}>
                                    {current_session.name}
                                    </Hide>

                                    <Hide hide={not_validate_value(current_session.start_time)}>
                                    {`${current_session.name} - ${timeTo12Hour(current_session.start_time)}`}
                                    </Hide>
                                </Typography>
                            </Button>

                            <Menu
                                anchorEl={open_classes_menu}
                                keepMounted
                                id="classes"
                                open={Boolean(open_classes_menu)}
                                onClose={handleClose}
                                PaperProps={{
                                    style: style.classMenu
                                }}
                            >
                                {classes.map((Session, Index) => (
                                    <MenuItem key={Index} style={style.classMenuItem}
                                              onClick={() => handleClassChange(Session)}>{`${Session.name} - ${timeTo12Hour(Session.start_time)}`}</MenuItem>
                                ))}

                                <Hide hide={classes.length !== 0}>
                                    <MenuItem style={style.classMenuItem} onClick={() => handleClose()}>No
                                        Classes</MenuItem>
                                </Hide>
                            </Menu>
                        </div>

                        {/*<Typography variant="subtitle1" className="font-weight-bold">*/}
                        {/*    {moment().format("dddd, DD MMM YYYY")}*/}
                        {/*</Typography>*/}

                        <div className="ml-3">
                            <FontSizeChanger
                                targets={['.content']}
                                options={{
                                    stepSize: 2,
                                    range: 15
                                }}
                                customButtons={{
                                    up: <ZoomInIcon/>,
                                    down: <ZoomOutIcon/>,
                                    style: style.fontSizeIcon,
                                }}
                            />
                        </div>

                    </Toolbar>

                    <Grid container spacing={2} style={style.item} className="content">
                        <Hide hide={view !== "Attendance" && !not_validate_value(current_session.id)}>
                            {attendance.map((Data, Index) => (
                                <Grid item xs={12} md={6} lg={4} key={Index}>
                                    <DisplayPersonData index={Index + 1} {...Data}/>
                                </Grid>
                            ))}
                        </Hide>

                        <Hide hide={view !== "WOD"}>
                            {workouts.map((Workout, index) => (
                                <Hide hide={index !== currentTab} key={Workout.header}>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant="h2" style={style.itemHeader}>
                                            {Workout.header}
                                        </Typography>

                                        <div className="h-100">
                                            <Typography variant="subtitle1" style={style.itemContent}>
                                                {Workout.content}
                                            </Typography>
                                        </div>
                                    </Grid>

                                    {/*<div style={{*/}
                                    {/*    width: '10%',*/}
                                    {/*    boxSizing: 'border-box',*/}
                                    {/*    paddingLeft: '4%',*/}
                                    {/*    height: '90%',*/}
                                    {/*}}>*/}
                                    {/*    <Divider orientation="vertical" variant="middle" style={{backgroundColor: 'white'}}/>*/}
                                    {/*</div>*/}

                                    {index < workouts.length - 1 && (
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h2" style={style.itemHeader}>
                                                {workouts[index + 1].header}
                                            </Typography>

                                            <div className="overflow-auto h-100">
                                                <Typography variant="subtitle1" style={style.itemContent}>
                                                    {workouts[index + 1].content}
                                                </Typography>
                                            </div>
                                        </Grid>
                                    )}

                                </Hide>
                            ))}
                        </Hide>


                        {/*<Hide hide={true}>*/}
                        {/*    <Hide hide={!open_leader_form}>*/}
                        {/*        <LeaderBoardFormModal*/}
                        {/*            open={open_leader_form}*/}
                        {/*            handleModalClose={handleCloseLeaderForm}*/}
                        {/*            helper_text={helper_text}*/}
                        {/*            data={leader_form_data}*/}
                        {/*            onChangeHandle={handleLeaderFormChange}*/}
                        {/*            handleSaveForm={handleSaveLeaderForm}*/}
                        {/*        />*/}
                        {/*    </Hide>*/}
                        {/**/}
                        {/*    <MaterialTable*/}
                        {/*        columns={leader_board_columns}*/}
                        {/*        data={leader_board_data}*/}
                        {/**/}
                        {/*        editable={{*/}
                        {/*            onRowDelete: async rowData => {*/}
                        {/*                await handleDeleteScore(rowData)*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/**/}
                        {/*        actions={[*/}
                        {/*            {*/}
                        {/*                icon: 'edit',*/}
                        {/*                tooltip: "Edit Score",*/}
                        {/*                onClick: (event, rowData) => handleOpenLeaderForm(rowData, rowData.index)*/}
                        {/*            },*/}
                        {/*            {*/}
                        {/*                icon: () => <Button*/}
                        {/*                    startIcon={<AddIcon/>} variant="outlined">Add*/}
                        {/*                    Score</Button>,*/}
                        {/*                tooltip: "Add Score",*/}
                        {/*                isFreeAction: true,*/}
                        {/*                onClick: (event, rowData) => handleOpenLeaderForm(rowData.data, undefined)*/}
                        {/*            },*/}
                        {/*        ]}*/}
                        {/**/}
                        {/*        style={style.leaderBoardTable}*/}
                        {/**/}
                        {/**/}
                        {/*        options={{*/}
                        {/*            showTitle: false,*/}
                        {/*            searchFieldAlignment: 'left',*/}
                        {/*            paging: false,*/}
                        {/*            sorting: false,*/}
                        {/*            filtering: false,*/}
                        {/*            actionsColumnIndex: -1,*/}
                        {/*        }}*/}
                        {/**/}
                        {/*        localization={{*/}
                        {/*            body: {*/}
                        {/*                emptyDataSourceMessage: <span className="text-white">No records to display</span>,*/}
                        {/*                editRow: {*/}
                        {/*                    deleteText: <span className="text-white">Are you sure you want to delete this row?</span>,*/}
                        {/*                },*/}
                        {/*            }*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/**/}
                        {/*</Hide>*/}
                    </Grid>


                </div>

            </Hide>

        </div>

    );
}


const style = {
    root: {
        // backgroundImage: `url(${BackGroundImage})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundSize: 'cover',
        // backgroundPosition: 'center',
        backgroundColor: '#5E5D5D',
        // backgroundColor: '#f8f9fa',
        height: '100vh',
        overflow: "hidden",
        color: 'white',
        // fontFamily: "Open Sans', sans-serif"
        fontFamily: "'Ubuntu', sans-serif",
    },
    container: {
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        paddingLeft: "2rem",
        paddingRight: "2rem",
    },
    logo: {
        height: "50px",
        marginRight: "1rem",
    },
    item: {
        // background: "rgba(0,0,0,0.5)",
        height: "auto",
        width: "100%",
        // border: "2px solid whitesmoke",
        // overflow: "hidden",
        padding: "0"
    },
    itemTab: {
        textAlign: "center",
        fontWeight: "bold",
        // background: "rgba(0,0,0,0.2)",
        // marginBottom: "1rem",
        // marginInlineStart: "1rem",
        // paddingTop: '1.25rem'
    },
    itemHeader: {
        // textAlign: "center",
        fontSize: "35.6px",
        fontWeight: "bold",
        // paddingBottom: '0.5rem',
        borderBottom: '2px solid white'
    },
    itemContent: {
        fontSize: "35.6px",
        fontWeight: "bold",
        padding: "0 0 3rem 0",
        margin: "0",
        // textAlign: "center",
        whiteSpace: 'pre-line',
    },
    btn: {
        borderColor: 'black',
        color: 'black',
    },
    fontSizeIcon: {
        width: '35px',
        height: '35px',
        padding: '0',
        margin: '0',
        border: 'none',
    },
    classMenu: {
        marginTop: "40px",
        // background: "rgba(0,0,0,0.9)",
        minWidth: '200px',
        border: "1px solid #3f51b5",
    },
    classMenuItem: {
        background: "none",
        minWidth: '200px',
    },
    leaderBoardTable: {
        background: 'rgba(255,255,255,0.5)',
        shadow: "none",
    }
}

